.mat-mdc-checkbox {
  background-color: var(--surface-primary-color);
  color: var(--font-primary-color);
  border: var(--default-border);
  border-radius: 4px;
}

.mat-mdc-checkbox-checked {
  background-color: var(--secondary-surface-color);
  border-color: var(--secondary-surface-color);
}

.mat-mdc-checkbox .mdc-form-field label {
  padding-right: 10px;
}

@media(max-width: 1440px) {
  .mat-mdc-checkbox .mdc-form-field label {
    font-size: var(--font-size-sm);
  }
}
