@import "./variables.css";
@import "./utils.css";
@import "./elements/index.css";
@import "./material/index.css";
@import "./material/chip-option.css";

* {
  box-sizing: border-box;
  font-family: var(--primary-font-family);
}

html,
body {
  height: 100%;
  background: var(--background-color);
  color: var(--font-primary-color);
  font-size: var(--font-primary-size);
  margin: 0;
}

a {
  text-decoration: none;
}

p {
  margin: 0;
}

.min-width-max-content {
  min-width: max-content;
}

@media(max-width: 1440px) {
  html,
  body {
    font-size: var(--font-tablet-size);
  }
}
