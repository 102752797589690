.sort-header_content-right .mat-sort-header-container {
  display: flex;
  justify-content: flex-end;
}

@media(max-width: 1440px) {
  .mdc-data-table__cell,
  .mdc-data-table__header-cell {
    font-size: var(--font-size-xs);
  }
}
