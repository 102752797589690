/* If we want to use the `select` as a separate component, we need to add the `.spbc-select` class to the element. */
.scpc-select.mat-mdc-select:focus-within {
  border-color: var(--brand-primary-color);
}

.scpc-select .mat-mdc-select-value {
  padding-right: var(--space-tiny);
}

.scpc-select.mat-mdc-select.ng-star-inserted {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--surface-primary-color);
  color: var(--font-primary-color);
  border: var(--default-border);
  border-radius: 4px;
  height: 40px;
  padding-left: var(--space-tiny);
  padding-right: var(--space-tiny);
  font-size: 1rem;
}

.scpc-select.mat-mdc-select.mat-mdc-select-disabled {
  background-color: var(--disabled-input-color);
}

.scpc-select.mat-mdc-select:not(.mat-mdc-select-disabled):focus {
  outline: 0;
  border-color: var(--brand-primary-color);
}

.scpc-select.mat-mdc-select .mat-mdc-select-value {
  color: var(--font-primary-color);
}

.scpc-select.mat-mdc-select-panel .mat-mdc-option,
.scpc-select.mat-mdc-autocomplete-panel .mat-mdc-option {
  font-size: 1rem;
  min-height: 32px;
}
