.widget-content-title {
  font-size: var(--font-size-xs);
  font-weight: 400;
  margin-bottom: 10px;
  color: var(--font-secondary-color);
  font-style: italic;
  white-space: nowrap;
}

.widget-icon {
  color: var(--brand-primary-color);
  margin-right: 8px;
}

.widget-table-container {
  overflow: auto;
}

.widget-table-container .mat-mdc-table {
  min-height: 190px;
}

.widget-table-container .mdc-data-table__table {
  background-color: var(--brand-secondary-color);
  border: 1px solid var(--brand-secondary-color);
  border-radius: 5px;
}

.widget-table-container .mdc-data-table__header-row,
.widget-table-container .mdc-data-table__row {
  height: fit-content;
  width: fit-content;
}

.widget-table-container .mdc-data-table__content .mdc-data-table__row:first-child .mat-mdc-cell {
  padding-top: 5px;
}

.widget-table-container .mdc-data-table__header-cell {
  font-weight: 500;
  padding: 10px;
}

.widget-table-container .mdc-data-table__cell {
  padding: 1px 10px;
  border-bottom: none;
  font-size: var(--font-size-xs);
}
