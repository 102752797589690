:root {
  --background-color: #e6e6e6;

  --surface-primary-color: #ffffff;
  --secondary-surface-color: #edf4fd;

  --brand-primary-color: #177cff;
  --brand-primary-contrast-color: #ffffff;
  --brand-primary-dark-color: #1d5ac5;
  --brand-primary-dark-contrast-color: #ffffff;
  --brand-primary-darkest-color: #003376;
  --brand-primary-darkest-contrast-color: #ffffff;
  --brand-primary-extra-light-color: #dfedff;
  --brand-primary-light-color: #63a8fc;
  --brand-primary-light-contrast-color: #000000;

  --brand-secondary-color: #edf4fd;
  --brand-secondary-contrast-color: #000000;
  --brand-secondary-dark-color: #1d5ac5;
  --brand-secondary-dark-contrast-color: #ffffff;

  --brand-accent-color: #9747ff;
  --brand-accent-contrast-color: #ffffff;
  --brand-accent-light-color: #f6f0fe;
  --brand-accent-dark-color: #3f307c;
  --brand-accent-dark-contrast-color: #ffffff;

  --warn-color: #c24f4f;
  --warn-contract-color: #ffffff;
  --warn-light-color: #c24f4f1a;

  --success-color: #2fa34f;
  --success-contract-color: #ffffff;

  --theme-primary-dark-color: #1f1f1f;

  --skeleton-gradient-color: #dadef4;

  --surface-box-shadow:
    0px 0px 1px rgba(0, 0, 0, 0.14),
    0px 1px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.18);

  /* Fonts */
  --font-primary-color: #001735;
  --font-primary-light-color: #0017358a;
  --font-secondary-color: #758192;
  --font-light-color: #ffffff;
  --font-accent-dark-color: #043a80;

  --font-tablet-size: 12px;
  --font-primary-size: 15px;
  --font-size-2xs: 0.67rem;
  --font-size-xs: 0.8rem;
  --font-size-sm: 0.94rem;
  --font-size-md: 1.07rem;
  --font-size-lg: 1.13rem;
  --font-size-xl: 1.33rem;
  --font-size-2xl: 1.6rem;

  --space-tiny: 10px;
  --space-small: 15px;
  --space-medium: 20px;
  --space-large: 25px;
  --space-huge: 30px;
  --space-gigantic: 35px;

  --border-color: #0000001f;
  --default-border: 1px solid var(--border-color);

  --disabled-button-color: #c2c8cf;
  --disabled-input-color: #efefef;

  --inactive-table-row-color: #f4f4f4;

  --positive-background-color: #2fa34f1a;
  --negative-background-color: #c24f4f1a;
  --positive-value-color: #2fa34f;
  --negative-value-color: #c24f4f;

  --primary-font-family: "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  /* Material. */
  --toggle-background-color: #0017351f;

  /* Material variables override */
  --mdc-typography-body1-letter-spacing: 0;
  --mdc-theme-text-primary-on-background: var(--font-primary-color);
}
