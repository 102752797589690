.scp-btn {
  --text-color: var(--font-primary-color);
  --background-color: var(--surface-primary-color);

  font-size: var(--font-size-md);
  text-decoration: none;
  padding: 8px;
  border-radius: 8px;
  border: none;
  font-weight: 500;
  transition: background-color, opacity 300ms ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  line-height: 1.5;
  cursor: pointer;
  color: var(--text-color);
  background-color: var(--background-color);
}

.scp-btn.primary {
  --background-color: var(--brand-primary-color);
  --text-color: var(--brand-primary-contrast-color);
}

.scp-btn.primary:hover {
  background-color: var(--brand-primary-light-color);
}

.scp-btn.primary:active {
  background-color: var(--brand-primary-dark-color);
}

.scp-btn.primary:disabled {
  background-color: var(--disabled-button-color);
}

.scp-btn.secondary {
  --background-color: var(--brand-secondary-color);
  --text-color: var(--brand-primary-color);
}

button mat-icon {
  height: 20px;
  width: 20px;
  font-size: 20px;
  cursor: pointer;
}

.scp-btn.warn {
  --text-color: var(--warn-contract-color);
  --background-color: var(--warn-color);
}


.scp-btn.outline {
  --background-color: transparent;

  border: var(--default-border);
}

.scp-btn.warn.outline {
  --text-color: var(--warn-color);
}
