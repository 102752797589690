.mat-mdc-paginator .mat-mdc-icon-button {
  background: var(--surface-primary-color);
  border: var(--default-border);
  border-radius: 8px;
  margin-inline: 4px;
}

.mat-mdc-paginator .mat-mdc-paginator-outer-container {
  border-top: var(--default-border);
}

.mat-mdc-paginator .mat-mdc-paginator-icon {
  fill: var(--brand-primary-color);
  width: 100%;
  height: 100%
}

.mat-mdc-paginator .mat-mdc-icon-button .mat-mdc-button-persistent-ripple {
  border-radius: 8px;
}

.mat-mdc-paginator .mat-button-disabled .mat-paginator-icon {
  fill: var(--brand-primary-light-color);
}

.mat-mdc-paginator .mat-mdc-select-arrow-wrapper {
  background-image: url(/assets/images/select-arrow-down-brand-color.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 24px;
  height: 24px;
}

.mat-mdc-paginator .mdc-notched-outline__trailing,
.mat-mdc-paginator .mdc-notched-outline__leading {
  border: none
}

.mat-mdc-paginator .mat-form-field-appearance-legacy .mat-form-field-underline {
  background: none;
}

.mat-mdc-paginator .mat-mdc-select-arrow {
  display: none;
}

.mat-mdc-paginator .mat-mdc-select:focus {
  outline: 0;
  border-bottom: 1px solid var(--brand-primary-color);
}

.paginator_sticky {
  bottom: 0px;
  position: sticky;
}

@media(max-width: 1440px) {
  .mat-mdc-paginator .mat-mdc-text-field-wrapper,
  .mat-mdc-paginator .mat-mdc-paginator-container {
    padding: 0;
  }

  .mat-mdc-paginator .mat-mdc-select {
    width: fit-content;
  }

  .mat-mdc-paginator .mat-mdc-paginator-page-size-select {
    width: 60px;
  }

  .mat-mdc-paginator .mat-mdc-paginator-range-label {
    margin-right: var(--space-tiny);
    margin-left: 0;
  }
}
