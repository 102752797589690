input[type="text"]:not(input[data-type="datepicker"]),
input[type="email"],
input[type="password"],
input[type="search"] {
  width: 100%;
  padding: 0 16px;
  background-color: var(--surface-primary-color);
  color: var(--font-primary-color);
  border: var(--default-border);
  border-radius: 4px;
  font-size: var(--font-primary-size);
  height: 40px;
}

input[type="search"] {
  background-image: url(/assets/images/search.svg);
  background-repeat: no-repeat;
  background-position: center right 10px;
}

input, textarea {
  outline-color: var(--brand-primary-color);
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  appearance: none;
}

input[data-type="datepicker"] {
  padding: 12px 16px;
  border: none;
  background: none;
  outline: 0;
}

input[disabled]:not(input[data-type="datepicker"]), textarea[disabled] {
  background-color: var(--disabled-input-color);
}

.ng-touched.ng-invalid[type="text"]:not(input[data-type="datepicker"]),
.ng-touched.ng-invalid[type="email"],
.ng-touched.ng-invalid[type="password"],
.ng-touched.ng-invalid[type="search"],
.ng-touched.ng-invalid:not([type]),
.mat-mdc-select.scpc-select.ng-touched.ng-invalid,
.ng-invalid > .autocomplete > .ng-touched {
  outline-color: var(--warn-color);
  border-color: var(--warn-color);
}

.ng-touched.ng-invalid[type="text"]:not(.ng-invalid[data-type="password"]),
.ng-touched.ng-invalid[type="email"],
.ng-touched.ng-invalid[type="search"] {
  background-image: url(/assets/images/error-icon.svg);
  background-repeat: no-repeat;
  background-position: center right var(--space-tiny);
}
