header.mat-dialog-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: var(--space-medium);
}

header.mat-dialog-header > * {
  font-size: var(--font-size-lg);
  font-weight: 500;
}

header.mat-dialog-header button[mat-dialog-close] {
  background-color: transparent;
  border: none;
  width: 22px;
  height: 22px;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

header.mat-dialog-header button[mat-dialog-close] mat-icon {
  width: 18px;
  height: 18px;
  font-size: 18px;
}
