.ndr-tabs .mat-mdc-tab {
  border: 1px solid var(--border-color);
  border-radius: 5px 5px 0px 0px;
  font-size: var(--font-primary-size);
  padding: 10px 0;
  text-align: center;
}

.ndr-tabs .mat-mdc-tab .mdc-tab__text-label {
  color: var(--brand-primary-color);
  font-weight: 400;
}

.ndr-tabs .mat-mdc-tab.mdc-tab--active {
  background-color: var(--brand-primary-color);
}

.ndr-tabs .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: var(--font-light-color);
  font-weight: 700;
}

.ndr-tabs .mat-mdc-tab.mdc-tab {
  height: min-content;
}

.ndr-tabs .mat-mdc-tab .mdc-tab-indicator__content--underline {
  display: none;
}

.ndr-tabs .mat-mdc-tab-body-wrapper {
  height: 100%;
  margin-top: var(--space-small);
}

.ndr-tabs .mat-mdc-tab-header-pagination-controls-enabled .mat-mdc-tab-header-pagination {
  display: none;
}

@media(max-width: 1440px) {
  .ndr-tabs .mat-mdc-tab-labels {
    flex-direction: column;
  }

  .ndr-tabs .mat-mdc-tab {
    font-size: var(--font-size-sm);
    padding: 5px 0;
  }

  .ndr-tabs .mat-mdc-tab-body-wrapper {
    height: 100%;
    margin-top: var(--space-tiny);
  }
}
