.mdc-evolution-chip-set .mdc-evolution-chip.mat-mdc-chip {
  margin-top: 0;
  margin-bottom: 0;
  height: 100%;
}

.mat-mdc-chip .mdc-evolution-chip__cell {
  position: relative;
  height: 100%;
}

.mat-mdc-chip-set .mdc-evolution-chip-set__chips {
  height: 100%;
}

.mat-mdc-chip .mat-mdc-chip-remove {
  position: absolute;
  top: -2px;
  left: -20px;
}

.mat-mdc-chip .mat-mdc-chip-action {
  height: 100%;
}

.mat-mdc-chip .mat-mdc-chip-remove .mat-icon {
  width: 15px;
  height: 15px;
  font-size: 15px;
}

.mat-mdc-chip .mat-mdc-chip-focus-overlay {
  background: none;
}

.mat-mdc-chip .mdc-evolution-chip__graphic.mat-mdc-chip-graphic .mdc-evolution-chip__checkmark {
  color: var(--brand-primary-color);
}

.mdc-evolution-chip__graphic.mat-mdc-chip-graphic {
  display: none;
}

