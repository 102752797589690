.mat-datepicker-toggle {
  color: var(--brand-primary-color);
}

.mat-date-range-input .mat-date-range-input-mirror {
  display: none;
}

.mat-date-range-input-container {
  justify-content: space-between;
}

.scpc-range-datepicker
  .mat-date-range-input-start-wrapper
  .mat-date-range-input-inner {
  position: relative;
}

.mat-date-range-input-container .mat-date-range-input-separator-hidden {
  opacity: 1;
}
