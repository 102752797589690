.mat-calendar-body-in-range::before {
  background: none;
}

.mat-calendar .mat-calendar-body-in-range {
  background: var(--secondary-surface-color);
  border-radius: 20px;
}

.mat-calendar-previous-button {
  order: -1;
}

.mat-calendar-spacer {
  display: none;
}

.mat-calendar-controls {
  justify-content: space-between;
}

.mat-calendar .mat-calendar-body-cell-content {
  line-height: 0;
  border-radius: 50%;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.mat-calendar .mat-calendar-body-cell {
  height: 90%;
}

.mat-calendar .mat-calendar-table {
  border-spacing: 2px;
  border-collapse: separate
}

.mat-datepicker-content .mat-datepicker-content-container .mat-calendar {
  height: 400px;
}

.mat-calendar .mat-calendar-body-cell::before,
.mat-calendar .mat-calendar-body-cell::after,
.mat-calendar .mat-calendar-body-cell-preview {
  top: 0;
  left: 2px;
  height: 100%;
}
