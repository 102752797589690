.app-skeleton {
  border-radius: 3px;
  background: linear-gradient(
    -45deg,
    var(--skeleton-gradient-color),
    rgb(0 0 0 / 0.1),
    var(--skeleton-gradient-color),
    var(--skeleton-gradient-color)
  );
  opacity: 0.6;
  background-size: 400% 400%;
  animation: skeleton-gradient-move 3s ease-in-out infinite;
}

@keyframes skeleton-gradient-move {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

h1.app-skeleton::before,
h2.app-skeleton::before,
h3.app-skeleton::before,
h4.app-skeleton::before,
h5.app-skeleton::before,
h6.app-skeleton::before,
p.app-skeleton::before,
span.app-skeleton::before,
time.app-skeleton::before,
a.app-skeleton::before {
  /* To emulate height of text. */
  content: ".";
  display: inline-block;
  color: transparent;
}
