::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: var(--brand-secondary-color);
}

::-webkit-scrollbar-thumb {
  background-color: var(--brand-primary-color);
  border: 3px solid transparent;
  border-radius: 10px;
  background-clip: content-box;
}
